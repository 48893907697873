import React, { useState, useEffect } from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import Clients from "../shared/clients";
import VideoModal from "../shared/videoModal";
import QueryModal from "../shared/queryModal";
import PromoModal from "../shared/promoModal";
import VideoPortfolio from "../shared/portfolioVideos";
import PricePackages from "../shared/packages";
import Testimonial from "../shared/testimonials";
import ContactForm from "../shared/contactForm";
import playIcon from "../../images/playicon.png";
import FeatureImg1 from "../../images/script.svg";
import FeatureImg2 from "../../images/voiceover.svg";
import FeatureImg3 from "../../images/board.svg";
import FeatureImg4 from "../../images/victory.png";
import aboutIcon1 from "../../images/icon1.png";
import aboutIcon2 from "../../images/icon2.png";
import aboutIcon3 from "../../images/icon3.png";
import aboutIcon4 from "../../images/icon4.png";
import aboutIcon5 from "../../images/icon5.png";
import aboutIcon6 from "../../images/icon6.png";
import awardImg1 from "../../images/award-1.png";
import awardImg2 from "../../images/award-2.png";
import awardImg3 from "../../images/award-3.png";
import awardImg4 from "../../images/award-4.png";
import awardImg5 from "../../images/award-5.png";
import awardImg6 from "../../images/award-6.png";
import cupImg from "../../images/cup.png";
import barkImg from "../../images/bark.png";
import trustpilotImg from "../../images/trustpilot.png";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const Home2 = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  const scrollToContactForm = () => {
    const contactForm = document.getElementsByClassName("contactBox")[0];
    if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth" });
    }
};

  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
  const [queryModalIsOpen, setQueryModalIsOpen] = useState(false);
  const [promoModalIsOpen, setPromoModalIsOpen] = useState(false);
  const [dataSrc, setDataSrc] = useState("");

  const openModel = (src) => {
    setVideoModalIsOpen(true);
    setDataSrc(src);
  };

  const closeModel = () => {
    setVideoModalIsOpen(false);
  };

  const openQueryModel = () => {
    setQueryModalIsOpen(true);
  };

  const closeQueryModel = () => {
    setQueryModalIsOpen(false);
  };

  const openPromoModal = () => {
    setPromoModalIsOpen(true);
  };

  const closePromoModal = () => {
    setPromoModalIsOpen(false);
  };

  // useEffect(() => {
  //     setTimeout(() => {
  //         openPromoModal();
  //     }, 8000);
  // }, []);

  return (
    <div className="site-wrapper">
      <Helmet>
        <title>Top Rated 3D Animation Company in USA</title>
        <meta
          name="description"
          content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
        />
        {/* OGTags */}
        <meta
          property="og:title"
          content="Top Rated 3D Animation Studio in USA"
        />
        <meta
          property="og:description"
          content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
        />
        <link rel="canonical" href="https://moonshotanimations.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="Moonshot Animations" />
        <meta property="og:url" content="https://moonshotanimations.com/" />
        <meta property="og:image" content={ogimg} />
      </Helmet>

      <VideoModal
        show={videoModalIsOpen}
        onCancel={closeModel}
        dataSrc={dataSrc}
      />

      <QueryModal show={queryModalIsOpen} onCancel={closeQueryModel} />

      <PromoModal show={promoModalIsOpen} onCancel={closePromoModal} />

      <Header />

      {/* Main Banner Section */}
      <section className="banner text-center">
        <h1
          className="bannerHeading"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
         
        </h1>
        
        <h2
          className="subHeading gradientOrange"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1300"
        >
         Stunning 3D Animations 
         <br />
         Within Your Budget
        </h2>
        {/* <div style={{ marginTop: "10px",marginBottom: "30px",fontSize:"20px", textDecoration:"underline" }}>New Year's: 20% Off on 3D Animations</div> */}
        <div>
          <a
            className="btn btnGradient mt-20 video-btn"
            data-aos="fade-up"
            data-aos-duration="1000"
            onClick={() =>
              openModel(
                "https://player.vimeo.com/video/913762871?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              )
            }
          >
            <img src={playIcon} alt="playIcon" />
            Play Showreel
          </a>
          <a
            className="btn btnGradientOne"
            //onClick={() => openQueryModel()}
            onClick={() => scrollToContactForm()}
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            Get Free Proposal{" "} 
          </a>
        
        </div>
        <div className="badges  mt-20">
          <div className="bark-widget">
            <a
              href="https://www.bark.com/en/us/company/moonshot-studio-inc/NjEAE/"
              target="_blank"
              className="bark-widget"
              data-type="pro"
              data-id="NjEAE"
              data-image="large-gold"
              data-version="3.0"
              rel="noreferrer"
            >
              <img src={barkImg} alt="Bark" />
            </a>
            <script
              type="text/javascript"
              src="https://d3a1eo0ozlzntn.cloudfront.net/assets/js/frontend-v2/widgets-v2.b9c82a02a3.v2.js"
              defer
            ></script>
          </div>
          {/* <div className="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="60e7e9bebb17b10001b482a4" data-style-height="52px" data-style-width="100%">

                        <a href="https://www.trustpilot.com/review/moonshotanimations.com" target="_blank" rel="noopener"><img src={trustpilotImg} alt="Trustpilot" /></a>
                    </div> */}

          {/* TrustBox widget - Micro Review Count  */}
          <div
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="60e7e9bebb17b10001b482a4"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="light"
            data-min-review-count="10"
            data-style-alignment="center"
          >
            <a
              href="https://www.trustpilot.com/review/moonshotanimations.com"
              target="_blank"
              rel="noopener"
            >
              <img src={trustpilotImg} alt="Trustpilot" />
            </a>
          </div>
          {/* End TrustBox widget */}
        </div>
      </section>

      {/* Our Clients Section */}
      <Clients />
      {/* Video Section */}
      <section className="videoIframe position-relative mb-53">
        <div className="container">
          <div className="text-center">
            <h2
              className="mainHeading varient1"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="700"
            >
              Top Rated 3D Animation Studio
            </h2>
          </div>
          <p
            className="text-center aos-init aos-animate"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="900"
          >
            Take a look at some of our most inspiring work for a variety of
            brands. We’ve put a lot of
            <br />
            love, and hard work into each to make it simply out of this world.
          </p>
          <div
            className="iframeV"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1200"
          >
            <iframe
              width="100%"
              src="https://player.vimeo.com/video/913762871?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              title="Showreel Video"
            ></iframe>
          </div>
        </div>
      </section>
      {/* About Section */}
      <section className="AboutSection text-center mb-53 bg-circle3">
        <div className="container">
          <div className="text-center">
            <h2
              className="mainHeading varient5"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              Why Moonshot Animations?
            </h2>
          </div>
          <p
            className="text-center"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            We are a highly skilled team of concept artists, writers, illustrators, designers,
            animators, marketers and engineers specialized in converting
            <br />
            ideas and messages in to beautiful 3D videos that work.
          </p>
          <div className="row">
            <div
              className="col-xl-4 col-md-4 col-sm-4"
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="600"
            >
              <div className="mb-4">
                <img src={aboutIcon1} alt="aboutIcon1" />
                <h4>Custom 3D Animations</h4>
                <p className="fs-6 text-center">
                We create tailored 3D animations that fit your unique vision. Every project is crafted to meet your specific needs.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-4 col-sm-4"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="600"
            >
              <div className="mb-4">
                <img src={aboutIcon2} alt="aboutIcon2" />
                <h4>Experienced Team</h4>
                <p className="fs-6 text-center">
                Our expert animators deliver stunning visuals. Years of experience ensure top-notch quality for every project.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-4 col-sm-4"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="600"
            >
              <div className="mb-4">
                <img src={aboutIcon3} alt="aboutIcon3" />
                <h4>On-Time Delivery</h4>
                <p className="fs-6 text-center">
                We stick to timelines without compromising quality. Your project will be ready exactly when you need it.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-4 col-sm-4"
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <div className="mb-4">
                <img src={aboutIcon4} alt="aboutIcon4" />
                <h4>Affordable Solutions</h4>
                <p className="fs-6 text-center">
                Get high-quality animations at competitive prices. We provide excellent value for your budget.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-4 col-sm-4"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <div className="mb-4">
                <img src={aboutIcon5} alt="aboutIcon5" />
                <h4>Collaborative Process</h4>
                <p className="fs-6 text-center">
                Work closely with our team throughout. Your feedback ensures the final product aligns perfectly with your goals.
                </p>
              </div>
            </div>
            <div
              className="col-xl-4 col-md-4 col-sm-4"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <div className="mb-4">
                <img src={aboutIcon6} alt="aboutIcon6" />
                <h4>High-Impact Visuals</h4>
                <p className="fs-6 text-center">
                Engage your audience with breathtaking 3D designs. We bring your ideas to life in the most captivating way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
       {/* Price Packages */}
       <PricePackages />
      {/* Testimonial Section */}
      <section className="CommentsSection overflow-hidden mb-53">
        <div className="text-center">
          <h2
            className="mainHeading varient4"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
          >
            Rated "Excellent" By Customers
          </h2>
        </div>
        <p
          className="text-center"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          Hear from our clients why they love working with us.
        </p>
        <Testimonial />
      </section>
      {/* Our Portfolio Section */}
      <section className="VideoSection bg-circle2 PrtfolioGrid mb-53">
        <div className="container-fluid">
          <div className="text-center">
            <h2
              className="mainHeading varient1"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
             3D Animations Portfolio
            </h2>
          </div>
          <p
            className="text-center"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            We’ve worked on each project with dedication and a commitment to
            excellence. We’ll go
            <br />
            to the moon and back to deliver the best.
          </p>
          <VideoPortfolio />
        </div>
      </section>

     


      {/* Feature Section */}
      <section className="AboutSection mb-53 bg-circle3 text-left feature">
        <div className="container">
          <div className="text-center">
            <h2
              className="mainHeading varient3"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              Here's how the Magic Happens
            </h2>
          </div>
          <div className="row">
            <div
              className="feature-card col-lg-6 col-xl-6 col-md-6"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <div className="row">
                <div className="col-lg-8 col-md-8 col-xs-12">
                  <p className="text-start number">01</p>
                  <h4 className="text-start">Concept & Storyboarding</h4>
                  <p className="text-start">
                  We work with you to shape your ideas into a clear, compelling concept. Together, we create storyboards to map out how your animation will unfold.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 feature-img">
                  <img src={FeatureImg1} alt="icon1" className="img-fluid" />
                </div>
              </div>
            </div>
            <div
              className="feature-card col-lg-6 col-xl-6 col-md-6"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <div className="row">
                <div className="col-lg-8 col-md-8 col-xs-12">
                  <p className="text-start number">02</p>
                  <h4 className="text-start">Modeling & Design</h4>
                  <p className="text-start">
                  Our team designs detailed 3D models and environments that bring your vision to life. Every element is crafted with precision to match your expectations.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 feature-img">
                  <img src={FeatureImg2} alt="icon2" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="feature-card col-lg-6 col-xl-6 col-md-6"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1300"
            >
              <div className="row">
                <div className="col-lg-8 col-md-8 col-xs-12">
                  <p className="text-start number">03</p>
                  <h4 className="text-start">Animation & Effects</h4>
                  <p className="text-start">
                  We add motion, textures, lighting, and effects to make your designs dynamic. Your story comes alive with our creative touch.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 feature-img">
                  <img src={FeatureImg3} alt="icon3" className="img-fluid" />
                </div>
              </div>
            </div>
            <div
              className="feature-card col-lg-6 col-xl-6 col-md-6"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1300"
            >
              <div className="row">
                <div className="col-lg-8 col-md-8 col-xs-12">
                  <p className="text-start number">04</p>
                  <h4 className="text-start">Review & Delivery</h4>
                  <p className="text-start">
                  We share drafts for your feedback and fine-tune the animation to perfection. Once approved, we deliver the final version exactly how you need it.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 feature-img">
                  <img src={FeatureImg4} alt="icon4" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      {/* Award Section */}
      <section className="awardSection overflow-hidden mb-53">
        <div className="text-center mb-140">
          <h2
            className="mainHeading varient6"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            Awards
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-12 award-images"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <img src={awardImg1} className="img-fluid" alt="awardImg1" />
              <img src={awardImg2} className="img-fluid" alt="awardImg2" />
              <img src={awardImg3} className="img-fluid" alt="awardImg3" />
              <img src={awardImg4} className="img-fluid" alt="awardImg4" />
              <img src={awardImg5} className="img-fluid" alt="awardImg5" />
              <img src={awardImg6} className="img-fluid" alt="awardImg6" />
            </div>
            <div
              className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              <img src={cupImg} className="img-fluid bouncing" alt="cupImg" />
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <ContactForm />

      <Footer />
    </div>
  );
};
export default Home2;
