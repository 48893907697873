import React, { Component, lazy, Suspense } from 'react';
import history from './history';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/js/dist/tab.js';
import 'bootstrap/js/dist/modal.js';
import "bootstrap/js/src/collapse.js";
import "./css/moonshot.css";
import "./css/responsive.css";

import { Router, Route, Switch } from 'react-router-dom'
import Home2 from "./components/home2/index";
import WhyMoonshot from "./components/whyMoonshotPage/index";
import OurClient from "./components/ourClient/index";
import OurProcess from "./components/ourProcess/index";
import PricePackages from "./components/packages/index";
import Portfolio from "./components/portfolio/index";
import CheckOut from "./components/checkoutPage/index";
import ThankYou from "./components/thankyouPage/index";
import PageNotFound from "./components/error404Page/index";
import Service2DAnimation from "./components/ourServices/2dAnimation";
import Service3dAnimation from "./components/ourServices/3dAnimation";
import ServiceExplainerVideo from "./components/ourServices/explainerVideo";
import ServiceLogoDesignAnimation from "./components/ourServices/logoDesignAnimation";
import ServiceMotionGraphics from "./components/ourServices/motionGraphics";
import ServicePromotionalVideo from "./components/ourServices/promotionalVideo";
import ServiceVideoEditing from "./components/ourServices/videoEditing";
import ServiceWhiteBoardAnimation from "./components/ourServices/whiteBoardAnimation";
import PrivacyPolicy from "./components/policyPages/privacyPolicy";
import RefundPolicy from "./components/policyPages/refundPolicy";
import TermsConditions from "./components/policyPages/termsConditions";
import Payment from "./components/paymentSuccess/index";

import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = lazy(() => import('./components/homePage/index'));

AOS.init({
  offset: 100,
  delay: 200,
  duration: 400,
  easing: 'ease',
  once: true,
  anchorPlacement: 'top-bottom',
  disable: function () {
    var maxWidth = 800;
    return window.innerWidth < maxWidth;
  },
});

window.onscroll = function () { myFunction() };
function myFunction() {
  if (document.body.scrollTop > 86 || document.documentElement.scrollTop > 86) {
    document.getElementById("header").classList.add("stickyHeader");
  }
  else {
    document.getElementById("header").classList.remove("stickyHeader");
  }
}

function ProtectedRoute({ component, path, exact }) {
  const page = localStorage.getItem("page");
  if (page == "checkout") {
    return <Route path={path} component={component} exact={exact} />;
  }
  else {
    history.replace("/");
    return <Route path="/" exact={true} component={Home} />;
  }
}

function App() {
  return (
    <Router history={history}>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route
            path="/"
            exact={true}
            component={Home}
          />
          <Route
            path="/why-moonshot"
            exact={true}
            component={WhyMoonshot}
          />
          <Route
            path="/our-services/2d-animation"
            exact={true}
            component={Service2DAnimation}
          />
          <Route
            path="/our-services/3d-animation"
            exact={true}
            component={Service3dAnimation}
          />
          <Route
            path="/our-services/explainer-video"
            exact={true}
            component={ServiceExplainerVideo}
          />
          <Route
            path="/our-services/logo-design-animation"
            exact={true}
            component={ServiceLogoDesignAnimation}
          />
          <Route
            path="/our-services/motion-graphics"
            exact={true}
            component={ServiceMotionGraphics}
          />
          <Route
            path="/our-services/promotional-video"
            exact={true}
            component={ServicePromotionalVideo}
          />
          <Route
            path="/our-services/video-editing"
            exact={true}
            component={ServiceVideoEditing}
          />
          <Route
            path="/our-services/white-board-animation"
            exact={true}
            component={ServiceWhiteBoardAnimation}
          />
          <Route
            path="/portfolio"
            exact={true}
            component={Portfolio}
          />
          <Route
            path="/our-process"
            exact={true}
            component={OurProcess}
          />
          <Route
            path="/packages"
            exact={true}
            component={PricePackages}
          />
          <Route
            path="/our-client"
            exact={true}
            component={OurClient}
          />
          <Route
            path="/privacy-policy"
            exact={true}
            component={PrivacyPolicy}
          />
          <Route
            path="/refund-policy"
            exact={true}
            component={RefundPolicy}
          />
          <Route
            path="/terms-and-conditions"
            exact={true}
            component={TermsConditions}
          />
          <ProtectedRoute
            path="/checkout"
            exact={true}
            component={CheckOut}
          />
          <Route
            path="/thankyou"
            exact={true}
            component={ThankYou}
          />
          <Route
            path="/payment-successful"
            exact={true}
            component={Payment}
          />
           <Route
            path="/home2"
            exact={true}
            component={Home2}
          />
          <Route
            //path="/404"
            //exact={true}
            component={PageNotFound}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

const PageLoader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

export default App;
