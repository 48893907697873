import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Services from "../../services/index";
import history from "../../history";


const ContactForm = () => {
  const [successMsg, setSuccessMsg] = useState("");
  


  const getValidationSchema = () => {
    setSuccessMsg("");
    return Yup.object().shape({
      fullname: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      phonenumber: Yup.string()
        .min(2, "Too Short!")
        .max(20, "Too Long!")
        .required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
    //  serviceItems: Yup.array().min(1, "Required at least one"),
      projectDetail: Yup.string().required("Required"),
    });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
// add to datalayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "formSubmission",
      formData: {
        
        email: values.email,

        phone_number: values.phonenumber},
    });
    try {
     const res = await Services.sendEmail(
       values.fullname,
       values.email,
       values.phonenumber,
       values.serviceItems,
       values.projectDetail,
       
     );

    //  Handle the response
     if (res.response === "Success") {
        setSuccessMsg(
          "Thank you! Your request has been successfully submitted."
        );
        history.push("/thankyou");
     } else {
       setSuccessMsg("Failed to submit form.");
     }
    } catch (error) {
      setSuccessMsg("An error occurred. Please try again.");
    }

    resetForm();
    setSubmitting(false);
  };

  

  return (
    <section className="contact-modal position-relative">
      <div className="container">
        <div
          className="contactBox"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1200"
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 p-0 d-none d-sm-block">
              <h2>Talk to Our Team Today</h2>
              <p>
                If you have any questions about our services, or want to discuss
                your project, shoot us an email at
              </p>
              <p>
                <a href="mailto:info@moonshotanimations.com">
                  info@moonshotanimations.com
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 formContainer">
              <Formik
                initialValues={{
                  fullname: "",
                  email: "",
                  phonenumber: "",
                  serviceItems: [],
                  projectDetail: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={getValidationSchema}
              >
                {({ errors, touched }) => (
                  <Form id="contactUsForm">
                    <div className="form-floating mb-3">
                      <Field
                        type="text"
                        className={
                          errors.fullname && touched.fullname
                            ? "form-control error"
                            : "form-control"
                        }
                        id="fullname"
                        name="fullname"
                        placeholder="Your Full Name"
                      />
                      <label htmlFor="fullname">Your Name</label>
                      {errors.fullname && touched.fullname ? (
                        <span className="errorMsg">
                          {" * " + errors.fullname}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-floating mb-3">
                      <Field
                        type="email"
                        className={
                          errors.email && touched.email
                            ? "form-control error"
                            : "form-control"
                        }
                        id="email"
                        name="email"
                        placeholder="Your Email Address"
                      />
                      <label htmlFor="email">Your Email Address</label>
                      {errors.email && touched.email ? (
                        <span className="errorMsg">{" * " + errors.email}</span>
                      ) : null}
                    </div>

                    <div className="form-floating mb-3">
                      <Field
                        type="tel"
                        className={
                          errors.phonenumber && touched.phonenumber
                            ? "form-control error"
                            : "form-control"
                        }
                        id="phonenumber"
                        name="phonenumber"
                        placeholder="Your Phone Number"
                      />
                      <label htmlFor="phonenumber">Phone Number</label>
                      {errors.phonenumber && touched.phonenumber ? (
                        <span className="errorMsg">
                          {" * " + errors.phonenumber}
                        </span>
                      ) : null}
                    </div>

                    <h4 style={{display:"none"}}>What Services Do You Want?</h4>

                    <div
                      className="row mb-4"
                      role="group"
                      aria-labelledby="checkbox-group" style={{display:"none"}}
                    >
                      <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value="2D Animation"
                            id="1"
                          />
                          <label className="form-check-label" htmlFor="1">
                            {" "}
                            2D Animation{" "}
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value="3D Animation"
                            id="1"
                          />
                          <label className="form-check-label" htmlFor="1">
                            {" "}
                            3D Animation{" "}
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value="White Board Animation"
                            id="1"
                          />
                          <label className="form-check-label" htmlFor="1">
                            {" "}
                            White Board Animation{" "}
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value="Promotional Video"
                            id="1"
                          />
                          <label className="form-check-label" htmlFor="1">
                            {" "}
                            Promotional Video{" "}
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value=" Other Projects"
                            id="1"
                          />
                          <label className="form-check-label" htmlFor="1">
                            {" "}
                            Other Projects{" "}
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value="Motion Graphics"
                            id="2"
                          />
                          <label className="form-check-label" htmlFor="2">
                            Motion Graphics
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value=" Explainer Video"
                            id="2"
                          />
                          <label className="form-check-label" htmlFor="2">
                            Explainer Video
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="serviceItems"
                            value="Video Editing"
                            id="2"
                          />
                          <label className="form-check-label" htmlFor="2">
                            Video Editing
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-floating mb-3" >
                      <Field
                        as="textarea"
                        className={
                          errors.projectDetail && touched.projectDetail
                            ? "form-control error"
                            : "form-control"
                        }
                        id="projectDetail"
                        name="projectDetail"
                        placeholder="Your Project Details"
                      />
                      <label htmlFor="projectDetail">Project Details</label>
                      {errors.projectDetail && touched.projectDetail ? (
                        <span className="errorMsg">
                          {" * " + errors.projectDetail}
                        </span>
                      ) : null}
                    </div>


                    <div className="d-flex justify-content-center mt-4">
                      <button type="submit" className="btn btn-primary">
                        Get Free Proposal
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

              {successMsg && (
                <div className="alert alert-info mt-3" role="alert">
                  {successMsg}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
